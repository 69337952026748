import {reducer} from './js/app/reducer';
import './SchemeColor';

window.kinosite = {
	reducer,
	additionalModules: {
		saleRulesPage: { getData: () => import("./js/templates/saleRules.html") },
		detailsPage: { getData: () => import("./js/templates/details.html") },
	},
};

