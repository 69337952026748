import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'пр. 25 Октября, 5',
		place: null,
		phoneNumbers: ['+7 (81371) 2-15-16'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/club.pobeda.cinema',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: 'https://yandex.ru/maps/-/CGt9qXkc',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
